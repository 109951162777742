import React from "react";
import { twMerge } from "tailwind-merge";

import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const typeToIconMap = {
  arrow: ArrowRightIcon,
  check: CheckCircleIcon,
};

export default function ContinueLessonButton({
  className,
  submitButtonLabel = "Continue Lesson",
  iconType = "arrow",
  ...props
}) {
  const IconElement = typeToIconMap[iconType];

  return (
    <button
      className={twMerge(
        "button-primary w-full self-center flex items-center justify-center",
        className
      )}
      data-testid="continue-lesson"
      type="submit"
      {...props}
    >
      {submitButtonLabel}
      <IconElement className="w-5 h-5 ml-2 mr-2" />
    </button>
  );
}
